import React, { useState, useEffect } from 'react';
import  {  useQueryParamString  }  from  'react-use-query-param-string' ;
import { ArchiveArticleDetail, MediaArticleDetail } from '@/molecules';
import { DateFormat } from '@/constants';
import { BaseLayout, Meta } from '@/layouts';
import dayjs from 'dayjs';

const PreviewPage: React.FC = () => {
  const [contentId] = useQueryParamString('contentId', '');
  const [draftKey] = useQueryParamString('draftKey', '');
  const [articleType] = useQueryParamString('articleType', '');

  const [postData, setPostData] = useState<null | any>(null);

  console.log(contentId, draftKey, articleType);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${process.env.GATSBY_MICRO_CMS_API_ENDPOINT}/${articleType}/${contentId}${
          draftKey ? `?draftKey=${draftKey}` : ''
        }`,
        {
          headers: {
            'X-API-KEY': process.env.GATSBY_MICRO_CMS_KEY || '',
          },
        },
      );
      const result = await response.json();
      console.log(result);
      setPostData(result);
    })();
  }, []);
  if (postData && articleType) {
    switch (articleType) {
      case 'media':
        return (
          <BaseLayout useHeader>
            <Meta title={postData.title} />
            <MediaArticleDetail
              title={postData.title}
              position={postData.position}
              name={postData.name}
              body={postData.body}
              mainVisualUrl={postData.mainVisual?.url}
              lastVisualUrl={postData.lastVisual?.url}
              publishDate={dayjs(postData.publishDate).format(DateFormat.YEAR_MONTH_DATE_JP)}
              credit={postData?.credit}
            />
          </BaseLayout>
        );
      case 'archive':
      default:
        return (
          <BaseLayout useHeader>
            <Meta title={postData.title} />
            <ArchiveArticleDetail
              title={postData.title}
              body={postData.body}
              mainVisualUrl={postData.mainVisual.url}
              publishDate={dayjs(postData.publishDate).format(DateFormat.YEAR_MONTH_DATE_JP)}
              infoList={postData.info}
            />
          </BaseLayout>
        );
    }
  }

  return <div></div>;
};

export default PreviewPage;
